import react from "react"
import APIrequest from "../db/APIrequests"
import { withProps } from "../helpers";
import { Formik, FormikValues } from "formik";
import {object, string} from "yup";
import { yupReturnText } from "../helpers/validationEnums";
import Navbar from "./navbar";

enum errorCodes{
  NoData, 
  CorrectLogin,
  IncorrectLogin,
  DifferentError
}

interface Props {
}

interface State {
  email?: string,
  password?: string,
  errors: errorCodes,
  passwordHidden: boolean
}

const API =  new APIrequest();

let loginSchema = object({
  email: string().email(yupReturnText.emailInvalid).required(yupReturnText.emailRequired),
  password: string().required(yupReturnText.passwordRequired)
})

class Login extends react.Component<Props, State> {

  /**
   *
   */
  constructor(props:Props) {
    super(props)

    this.state = {errors: 0, passwordHidden: true}
  }

  login(formikData:FormikValues){
    const data = {
      user: formikData.email,
      password: formikData.password
    }

    API.loginUser(data)
      .then(response => {
        if(response && response.response && response.response.status == 400){
          this.setState({errors: (response.response.data.CredentialsIncorrect == true ? 2 : 3)})
        }
        if(response.status == 200 && response.data.token) {
          let redirectUrl = JSON.parse(window.atob(response.data.token.split(".")[1])).data.ID
          window.location.assign(`/account/overview/${redirectUrl}`)
        }
      })
      .catch(e => {return e})
  }

  togglePasswordHidden(){
    this.setState({passwordHidden: !this.state.passwordHidden})
  }

  render(){

    let {errors} =  this.state

    return(
      
      <div id={"users"}>
      <Navbar context={{ setButton1: "Disabled", setButton2: "Disabled", button1Click: (value: number) => {}, button2Click: (value: number) => {}}}></Navbar>
        <main>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={loginSchema}
                  onSubmit={(values, actions) => {
                    this.login(values)
                  }}
                  validateOnChange={true}
                >
                  {props => (
                    <form noValidate onSubmit={props.handleSubmit}>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <h1>Login</h1>
                          </div>
                        </div>
                        {errors === 2 &&
                          <div className="alert alert-danger mt-2" role="alert">
                            Uw login gegevens zijn niet correct.
                          </div>}
                        {errors === 3 &&
                          <div className="alert alert-danger mt-2" role="alert">
                            Er is iets verkeerd gegaan. Het kan zijn dat u nog een account moet aanmaken.
                          </div>}
                        <div className="row mt-3">
                          <div className="col-12 col-sm-4">
                            <label htmlFor="exampleInputEmail1" className="form-label">E-mailadres</label>
                          </div>
                          <div className="col-12 col-sm-8">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              aria-describedby="emailHelp"
                              name="email"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            {props.errors.email && props.touched.email ? 
                              <div className="alert alert-danger" role="alert">
                                {props.errors.email}
                              </div>
                              : null
                              }
                            <div id="emailHelp" className="form-text">We zullen uw e-mailadres nooit met anderen delen.</div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-sm-4">
                            <label htmlFor="exampleInputPassword1" className="form-label" >Wachtwoord</label>
                          </div>
                          <div className="col-12 col-sm-8 revealer-container">
                            <input
                              type={this.state.passwordHidden === true ? "password" : "text"}
                              className="form-control"
                              id="password"
                              name="password"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <i className={"revealer-icon fa-solid "+ (this.state.passwordHidden === true ?  "fa-eye-slash" : "fa-eye")} onClick={e => this.togglePasswordHidden()}></i>
                            {props.errors.password && props.touched.password ?
                              <div className="alert alert-danger" role="alert">
                                {props.errors.password}
                              </div>
                              : null
                              }
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 d-flex justify-content-end">
                            <button className="btn btn-primary" type='submit'>Inloggen</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </div>
    
    )
  }
}

export default withProps(Login)
