import react, { ReactNode } from "react";
import APIrequest from "../db/APIrequests"
import { withProps } from "../helpers";
import { adminDetails, admins } from "../db/model";
import { Outlet } from "react-router-dom";
import PageError from "./components/ErrorMessages/pageError";
import Error from "./components/Error"
import Navbar from "./navbar";

interface Params{
  adminID: number
}

interface Props{
  params: Params
}
interface State{
  data?: adminDetails
  user?: Partial<admins>
  errorMessage?: string
  errorCode?: number
  saveStateButton: "Opgeslagen" | "Opslaan"
  triggerProfileClick: number
}

const API = new APIrequest();

class AccountOverview extends react.Component<Props, State>{

  constructor(Props:Props){
    super(Props)
    this.state = { saveStateButton: "Opgeslagen", triggerProfileClick: 0}

    this.fetchData =  this.fetchData.bind(this);
  }

  componentDidMount(): void {
    this.fetchData();
  }


  async fetchData(){
    const url = "/API/account/" + this.props.params.adminID;

    await API.fetchData(url).then(response => {
      if(response.status === 200 && response.data){
        this.setState({data: response.data.data, user: response.data.admin})
      }else{
        this.setState({errorCode: response.response.status, errorMessage: response.response.data})
      }
    }).catch(e => console.log(e))
  }

  handleDetailChange(e:any){
      e.preventDefault();
      let value:string | number | undefined = e.target.value;
      let target: keyof adminDetails = e.target.name;

      let {data} =  this.state

      if(target && data){
        data[target] = value !== "" ? value : undefined;

        this.setState({data: data, saveStateButton: "Opslaan"})
      }
  }


 async saveData(){
    const {data} =  this.state

    let url = "/API/account/" + this.props.params.adminID;

    await API.updateAdminDetails(url, data).then(response => this.fetchData()).catch(e => console.log(e))
    this.setState({saveStateButton: "Opgeslagen"})
  }


  render(): react.ReactNode {
    
    let {data, user} = this.state
    const renderForm: Array<ReactNode> = [];

    if(data){
      if(user){
        renderForm.push(
          <div key={"user"}>
            <div className={"row mb-2 "}>
              <div className="col-4 padding-user">
                <span id={"text"} className="label"> Gebruiker </span>
              </div>
              <div className="col-8 mb-2">
                <p>{user.user}</p>
              </div>
            </div>
          </div>
        )
      }

      let key: keyof adminDetails;

      for(key in data){
        if(key == "ID" || key == "adminID"){
          continue
        }
        renderForm.push(
        <div>
          <div className={"row mb-2 "}>
            <div className="col-4">
              <span id={"text"} className="label">{key}</span>
            </div>
            <div className="col-8">
              <input id={"input-"} name={key} key={key} className="form-control" placeholder="Voer uw gegevens in..."
              onChange={e =>  this.handleDetailChange(e)} value={data[key] ? data[key] :""}></input>
            </div>
          </div>
        </div>)
      }
    }

    return(
      <>
      <div id={"users"}>
      <Navbar context={{ setButton1: "Nieuw Profiel", setButton2: this.state.saveStateButton, button1Click: (value: number) => {this.setState({triggerProfileClick: this.state.triggerProfileClick +1})}, button2Click: (value: number) => {this.saveData()}}}></Navbar>
      {API._loggedIn === true && this.props.params.adminID == API.tokenData?.data.ID &&   
      <main>
        <div className="container">
          <div className="row">

            {/* Client data */}
            <div className="col-12 col-md-6">
              <h1>Uw gegevens</h1>
              <hr/>
              <div className="user-data">
                {renderForm}
              </div>
            </div>

            {/* Profiles overview */}
            <div className="col-12 col-md-6">
              <h1>Profiel(en)</h1>
              <hr />
              <Outlet context={{addProfileButton: this.state.triggerProfileClick }}/>
            </div>

          </div>
        </div>
      </main>}

      {this.state.errorCode !== undefined && this.state.errorMessage !== undefined && <Error data={{errCode: this.state.errorCode, errMessage: this.state.errorMessage}}/>}
    
        </div>
      </>
    )
  }
}

export default withProps(AccountOverview)
