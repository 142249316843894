import react, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { withProps } from "../helpers";

interface Triggers{
  context:{
    clicked : boolean,
    returnValue : Function,
    name: string,
  }
}

function GenericModal(Props: Triggers) {
  const [show, setShow] = useState(false);
  const [returnValue, setReturnValue] = useState("")

  const handleClose = () => {setReturnValue("Closed"); setShow(false)};
  const handleShow = () => setShow(true);

  useEffect(() => {
    if(Props.context.clicked !== false){
      handleShow();
      setReturnValue("");
    }
  }, [Props.context.clicked])

  useEffect(() => {
    if(returnValue){
      Props.context.returnValue.call(returnValue, returnValue)
      if(returnValue === "Clicked"){
        setShow(false)
      }
    }
  },[returnValue])

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal 
        show={show} 
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Bevestig profielnaam</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Voor een profiel stelt men eenmalig een naam in. Dit voorkomt misbruik van het systeem.<br/>
            <br/>
            Klopt de naam: {Props.context.name} ?<br/>
            <br/>
            Indien u het nog wilt wijzigen kunt u op Sluiten klikken. Als u op "Sla profielnaam op" klikt wordt de naam vastgezet.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Sluiten</Button>
          <Button variant="primary" onClick={e => {setReturnValue("Clicked")}}>Bevestigen</Button>
        </Modal.Footer>
        
      </Modal>
    </div>
  );
}

export default withProps(GenericModal)
