import react, { ReactNode } from "react"
import { withProps } from "../helpers"
import API from "../db/APIrequests"
import { clienten } from "../db/model";
import DefaultModal from "./defaultModal";

interface Props{
  data: {
    addProfileButton: number
  }
  params: {
    adminID: number
  }
}
interface State{
  clientData: Array<clienten> | clienten | undefined;
  clickedDeleteButton: boolean;
  target: number | undefined; 
  testAccountWarnings: "Delete Clicked" | "Create clicked" | undefined
}
const APIrequest = new API();

class ProfileOverview extends react.Component<Props, State>{
  constructor(Props:Props){
    super(Props)

    this.state = {clientData: undefined, clickedDeleteButton: false, target: undefined, testAccountWarnings: undefined};

    this.fetchProfiles =  this.fetchProfiles.bind(this)
    this.createNewProfile =  this.createNewProfile.bind(this)
  }

  componentDidMount(): void {
    this.fetchProfiles();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps.data.addProfileButton !== this.props.data.addProfileButton){
      this.createNewProfile("add profile")
    }
  }

  async fetchProfiles(){
    let url =  "/API/account/" + this.props.params.adminID + "/profiles";
    try{
      await APIrequest.fetchData(url)
        .then(result => {
          if(result.data){
            this.setState({clientData: result.data})}
          else{
            this.setState({clientData: undefined})}})
        .catch(e => console.log(e))

    }
    catch(e){
      console.log(e)
    }
  }

  async createNewProfile(e:any){

    let {clientData} =  this.state

    if(APIrequest.tokenData?.data.PermissionLevel === "Test" && clientData !== undefined){
      this.setState({testAccountWarnings: "Create clicked"})
      setTimeout(() => {
        this.setState({testAccountWarnings: undefined})
      }, (4000));
      return
    }
    let url = "/API/account/" + this.props.params.adminID + "/profiles"
    try{

      let request = await APIrequest.createProfile(url).then(response => {

        if(response && response.status === 200){
          return this.setState({clientData: response.data})
        }

      }).catch(e => console.log(e))
    }
    catch(e){
      console.log(e)
    }
  }

  async deleteProfile(e:any, target:string){

    if(APIrequest.tokenData?.data.PermissionLevel === "Test"){
      return
    }

    const url = "/API/account/" + this.props.params.adminID + "/profiles/" + target;

    try{
        let response = await APIrequest.deleteProfile(url)
        if(response.status === 200){
          this.setState({clickedDeleteButton: false})
          this.fetchProfiles()
        }
    }
    catch(e){
      console.log(e)
    }
  }

  handleDeleteButtonCLick(e:any, index?: number){
    if(APIrequest.tokenData?.data.PermissionLevel === "Test"){
      this.setState({testAccountWarnings: "Delete Clicked"})
      setTimeout(() => {
        this.setState({testAccountWarnings: undefined})
      }, (4000));
      return
    }
    let {clientData} = this.state
    let target:number | undefined;

    if(clientData && !Array.isArray(clientData)){
      target = clientData.ID
    }
    else if(index != undefined && Array.isArray(clientData)){
      target = clientData[index].ID
    }
    if(target){
      this.setState({clickedDeleteButton:true, target: target })
    }
  }

  render(): react.ReactNode {

    let {clientData} = this.state

    let PermissionLevel;
    if(APIrequest.tokenData?.data.PermissionLevel){
      PermissionLevel = APIrequest.tokenData.data.PermissionLevel
    }

    let renderClientTable: Array<ReactNode> = [];

      let data: Array<any> = [];

      if(clientData != undefined && clientData instanceof Array){
        clientData.forEach((clientRow: clienten, index:number) => {
          data.push(
            <tr key={index}>
              <td className="profile-data"> {index+1} </td>
              <td className="profile-data"> {clientRow.profielnaam ? clientRow.profielnaam : "Geen naam ingevuld"}</td>
              <td>
                <button className="btn btn-secondary btn-sm me-2"
                  onClick={e =>(window.location.assign("/user/"+clientRow.adminID +"/edit/"+ clientRow.ID))}>
                    <span className="fa-solid fa-pen-to-square"></span>
                </button>
                <button className="btn btn-secondary btn-sm me-2"
                  onClick={e =>(window.location.assign("/user/"+clientRow.adminID +"/edit/"+ clientRow.ID+"/viewPDF"))}>
                    <span className="fa-solid fa-file-pdf"></span>
                </button>
                <button className="btn btn-secondary btn-sm"
                  onClick={e =>(this.handleDeleteButtonCLick(e, index))}>
                    <span className="fa-solid fa-trash-can"></span>
                </button>
              </td>
            </tr>
          )
        })
        data.push(
          <tr key={"Add Profile"}>
            <td key={"default"}></td>
            <td key={"empty"}></td>
          <td>
            <button className="btn btn-primary btn-sm" onClick={e =>(this.createNewProfile(e))}><span className="fa-solid fa-add"></span> Nieuw profiel</button>
          </td>
        </tr>
        )
      }
      else if(clientData != undefined && clientData.ID){
        data.push(
          <tr key={1}>

            <td className="profile-data"> 1 </td>
            <td className="profile-data"> {clientData.profielnaam ? clientData.profielnaam : "Geen naam ingevuld"}</td>
            <td>
              <button className="btn btn-secondary btn-sm me-2"
                onClick={e =>(window.location.assign("/user/"+ (clientData && !Array.isArray(clientData)? clientData.adminID : "")  +"/edit/"+ (clientData && !Array.isArray(clientData) ? clientData.ID :"")))}>
                  <span className="fa-solid fa-pen-to-square"></span>
              </button>
              <button className="btn btn-secondary btn-sm me-2"
                onClick={e =>(window.location.assign("/user/"+ (clientData && !Array.isArray(clientData)? clientData.adminID : "")  +"/edit/"+ (clientData && !Array.isArray(clientData) ? clientData.ID :"")+"/viewPDF"))}>
                  <span className="fa-solid fa-file-pdf"></span>
              </button>
              <button className="btn btn-secondary btn-sm"
                onClick={e =>(this.handleDeleteButtonCLick(e))}>
                  <span className="fa-solid fa-trash-can"></span>
              </button>
            </td>
          </tr>
        )
        data.push(
          <tr key={"Add Profile"}>
            <td key={"default"}></td>
            <td key={"empty"}></td>
            <td>
              <button className="btn btn-primary btn-sm" onClick={e =>(this.createNewProfile(e))}><span className="fa-solid fa-add"></span> Nieuw profiel</button>
            </td>
          </tr>
        )
      }
      else{
        data.push(
          <tr key={"Add Profile"}>
            <td key={"default"}></td>
            <td key={"empty"}></td>
            <td>
              <button className="btn btn-primary btn-sm" onClick={e =>(this.createNewProfile(e))}><span className="fa-solid fa-add"></span> Maak nieuw profiel aan</button>
            </td>
          </tr>
        )
      }

      renderClientTable.push(
        <table className="table">
          <thead>
          <tr>
            <th scope="col" key={"amount"}>#</th>
            <th scope="col" key={"name"}>Naam</th>
            <th scope="col" key={"options"}>Opties</th>
          </tr>
          </thead>
          <tbody>
            {data.map(row => {return row})}
          {clientData && 
            <tr>
              <td colSpan={2}>
                <div className="header-info">
                  <h2>Legenda</h2>
                  <p>
                    <span className="fa-solid fa-pen-to-square"></span> Bewerk profiel<br />
                    <span className="fa-solid fa-file-pdf"></span> Preview PDF<br />
                    <span className="fa-solid fa-trash-can"></span> Verwijder profiel<br />
                  </p>
                </div>
              </td>
            </tr>
          }
          </tbody>
        </table>
      )

    return(
      <div key={"container"}>
        <DefaultModal context={
          {
            clicked: this.state.clickedDeleteButton,
            returnValue: (value: string, target: string) => { value === "Closed" ? this.setState({ clickedDeleteButton: false }) : this.deleteProfile(value, target) },
            action: "Wilt u het hele profiel definitief verwijderen?",
            target: this.state.target
          }} />

        {this.state.testAccountWarnings !== undefined &&
          <div className="alert alert-danger" role="alert">
            <p> Op dit account zijn bepaalde functionaliteiten gelimiteerd</p>
          </div>
        }
        {renderClientTable}

      </div>
    )
  }
}

export default withProps(ProfileOverview)
