import react, { ReactNode } from "react";
import { dataBox } from "../db/model";
import { stringifyData, withProps } from "../helpers";
import { Overlay, OverlayTrigger, Popover } from "react-bootstrap";



interface propdata{
  optionaldata: {[key:string]: string},
  setOptionalData: Function,
  resetRow: Function,
}

interface Props{
  data: propdata,
}

interface State{
  id?: number,
  clientID?: number,
  data?: Array<dataBox>
  resetRowTarget: undefined | string
  show: boolean
}

class OptionalData extends react.Component<Props, State>{

  popoverTimeout: number | undefined =  undefined;
  maxLength = 60;

  constructor(Props: Props) {
    super(Props);
    this.state = { resetRowTarget: undefined, show: false }

    this.setData = this.setData.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps.data.optionaldata !== this.props.data.optionaldata){
      this.setData();
      this.setState({show: false})
    }
  }

  setData(){
    if(this.props){
      const data =  this.props.data?.optionaldata
      let dataCollection: Array<dataBox> = [];
      if(data){
        for (let key in data){
          const dataBox: dataBox = JSON.parse(data[key])
          dataCollection.push(dataBox)
        }
      }
      if(dataCollection !== undefined){
        this.setState({data: dataCollection})
      }
    }
  }

  toggleRow(e:any, index:number){
    let data = this.state.data
    if(data){
      data[index].active = Number(data[index].active) === 0 ? 1 : 0
    }
    this.setState({data: data})
    this.handleTextInput("boop")
  }

  handleTextInput(event:any, key?:number){
    let data = this.state.data
    if(key != undefined && data){
      data[key].elementText = event.target.value
    }
    this.setState({data: data})
    if(this.props.data && data != undefined){
      let optionaldata = stringifyData(data)
      this.props.data.setOptionalData.call(this, optionaldata)
    }
  }
  
  popoverHandler(){
    if(this.state.show == true && this.popoverTimeout !== undefined){
      clearTimeout(this.popoverTimeout)
    }
    setTimeout(() => {
      this.setState({show: false})
    }, 5000);
  }

  render(): react.ReactNode {
    let {data} = this.state

    let dataBoxArray: Array<ReactNode> = [];

    const popover = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Invoerveld herstellen?</Popover.Header>
        <Popover.Body>
          <div className="popover-reset" onClick={e => {this.props.data.resetRow.call(this, this.state.resetRowTarget); this.setState({show: false})}}>
            <span className="fa-solid fa-check"></span> Ja
          </div>
          <div className="popover-reset" onClick={e => this.setState({resetRowTarget: undefined, show: !this.state.show})}>
            <span className="fa-solid fa-xmark"></span> Nee
          </div>
        </Popover.Body>
      </Popover>
    );

    if(data){
      data.forEach((box, index) => {
        dataBoxArray.push(
          <div key={index}>
            <div className="row">
              <div className="col-12">
                <hr className="hr-lighter"/>
              </div>
            </div>
            <div className={"row mb-2 " + ((box.active == true) ? "" :"opaque") }>
              <div className="col-12 col-md-3 mb-2 mb-md-0">
                <input id={"text-"+index} type="text" className="form-control" value={box.elementName} readOnly/>
              </div>
              <div className="col-12 col-md-6 mb-2 mb-md-0">
                <textarea id={"input-"+index} rows={2} key={index} className="form-control" placeholder="Voer uw tekst in..." maxLength={this.maxLength} onChange={e => this.handleTextInput(e, index)} value={box.elementText}></textarea>
              </div>
              <div className="col-12 col-md-3" id={"svg"+index}>

                <span className="d-inline d-md-none h3 mr-p5">Acties:</span>

                {/* // <!-- active/inactive svg--> */}
                {box.active == true &&
                  <span className="btn icons-inline icon-toggle-on" onClick={e => this.toggleRow(e, index)}></span>
                }
                {box.active == false &&
                  <span className="btn icons-inline icon-toggle-off" onClick={e => this.toggleRow(e, index)} ></span>
                }

                {/* <!-- clear inputs --> */}
                <OverlayTrigger trigger="click" placement="right" onEnter={() => {setTimeout(() => {
                  this.setState({resetRowTarget: undefined, show: false})
                }, (
                  10000
                ));}} overlay={popover} show={box.columnName == this.state.resetRowTarget ? this.state.show : false}>
                  <span className="btn icons-inline icon-delete" onClick={() => {this.setState({resetRowTarget: box.columnName, show: true}); clearTimeout(this.popoverTimeout)}}></span>
                </OverlayTrigger>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-md-3"></div>
              <div className="col-12 col-md-6">

                <span className="input-visibility">
                  Onderwerp is

                  {(Boolean(box.active) === true) &&
                    <span className="visibility-on"> actief <span className="fa-solid fa-check"></span></span>
                  }
                  {(Boolean(box.active) !== true) &&
                    <span className="visibility-off"> inactief <span className="fa-solid fa-xmark"></span></span>
                  }
                </span>

                <span className={"counter-"+index +" float-end"} >
                  {box.elementText.length} / {this.maxLength}
                </span>

              </div>
              <div className="d-none d-md-block col-12 col-md-3"></div>
            </div>

          </div>
        )
      })
    }

    return(
      <>
      {dataBoxArray.map(element => element)}
      </>
    )
  }
}

export default withProps(OptionalData)
