import React from 'react';
import ReactDOM from 'react-dom/client';
import Login from './pages/login';
import reportWebVitals from './reportWebVitals';
import Activate from './pages/activateAccount';
import Edit from './pages/edit';
import OptionalData from './pages/optionalData';
import {
  createBrowserRouter,
  matchPath,
  OutletProps,
  RouterProvider,
} from "react-router-dom";
import Navbar from './pages/navbar';
import PDFPreview from './pages/pdf'
import CreateAccount from './pages/createAccount';
import AccountOverview from './pages/accountOverview';
import ProfileOverview from './pages/profileOverview';
import FormikTest from './pages/formik';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/aanmelden",
    element: <CreateAccount/>
  },
  {
    path: "/user/:id/edit/:profileID",
    element: <Edit />,
    children: [
      {
        index: true,
        element: <OptionalData />
      }
    ],
  },
  {
    path: "/user/:id/edit/:profileID/viewPDF",
    element: <PDFPreview />,
  },
  {
    path: "/account/overview/:adminID",
    element: <AccountOverview />,
    children:[{
      index: true,
      element: <ProfileOverview />,
    }]
  },
  {
    path: "/testpage",
    element: <FormikTest />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <RouterProvider
      router={router}
    />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
